import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import withOrderContext from '../withOrderContext';
import { Button } from './home/v3/styledComponents';
import routesMap from '../Routes';

const CTALink = ({
  children, fromInteraction, defaultLabel, large, transparent, linkState,
}) => {
  const label = fromInteraction !== 'Pricings'
    ? defaultLabel
    : 'Revenir vers le parcours';

  return (
    <Link
      style={{ textDecoration: 'none' }}
      to={routesMap.Step1.url}
      state={linkState}
    >
      {!children
        ? <Button navy={!transparent} large={large}>{label}</Button>
        : React.cloneElement(children, { label })}
    </Link>
  );
};

CTALink.propTypes = {
  fromInteraction: PropTypes.string.isRequired,
  children: PropTypes.node,
  defaultLabel: PropTypes.string,
  large: PropTypes.bool,
  transparent: PropTypes.bool,
  linkState: PropTypes.shape({}),
};

CTALink.defaultProps = {
  children: undefined,
  clone: false,
  large: false,
  transparent: false,
  defaultLabel: 'Prendre rendez-vous',
  linkState: {},
};

export default withOrderContext(CTALink);
