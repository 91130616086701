import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { colors, mobileThresholdPixels } from './styledComponents';
import withAppContext from '../../../withAppContext';
import { getSubCategory, getSubCategories } from './pricesTree';
import iconClothes from '../../../assets/pricing/clothes.svg';
import iconDecorations from '../../../assets/pricing/decorations.svg';
import iconCreation from '../../../assets/pricing/creation.svg';
import arrowBottom from '../../../assets/pricing/arrow.bottom.svg';
import arrowRight from '../../../assets/pricing/arrow.right.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.navy};
  border-radius: 3px;
  border: 1px solid ${colors.blueGrey};
  height: fit-content;
  margin-right: 71px;
  width: 315px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
    margin-right: 0px;
    border: 0px;
    background-color: ${colors.lightGrey};
  }
`;

const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: hidden;
`;

const CategoryContainer = styled.div`
  font-family: Roboto;
  ${({ isMain }) => isMain && 'font-weight: bold;'}
  height: ${({ isMain }) => isMain ? '70' : '49'}px;
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ isFirst }) => isFirst && 'border-radius: 3px 3px 0px 0px;'}
  ${({ hasBorderBottom }) => hasBorderBottom
    ? `border-bottom: 1px solid ${colors.blueGrey};`
    : 'border-radius: 0px 0px 3px 3px;'
}
  padding :${({ isMain }) => isMain ? '20px 17px 20px 20px' : '11px 17px 11px 62px'};
  background-color: ${({ isSelected, isMain }) => {
    if (isSelected && !isMain) {
      return colors.lightBlue2;
    }
    return isMain ? colors.lightBlue3 : colors.white;
  }};
  transition: all 0.3s ease;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    padding :${({ isMain }) => isMain ? '20px 48px' : '10px 48px 10px 90px'};
    ${({ isFirst }) => isFirst && `border-top: 1px solid ${colors.blueGrey};`}
  }
`;

const FirstPartCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.img`
  margin-right: 10px;
`;

const CategoryLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const YellowDiv = styled.div`
  color: ${colors.yellow};
  font-weight: 500;
  font-size: 11px;
  margin-bottom: 8px;
`;

const MobileDivClose = styled.div`
  background: ${colors.white};
  border: 2px solid ${colors.yellow};
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 24px;
  margin: 24px;
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const categories = [
  {
    slug: 'cloth',
    label: 'Vêtements et accessoires',
    image: iconClothes,
    subCategories: getSubCategories('cloth'),
  },
  {
    slug: 'deco',
    label: 'Décorations',
    image: iconDecorations,
    subCategories: getSubCategories('deco'),
  },
  {
    slug: 'creation',
    label: 'Création d’accessoires',
    image: iconCreation,
    subCategories: getSubCategories('creation'),
  },
];

const PricingCategoriesSelector = ({
  selectedCategory, selectedSubCategory, selectCategory, selectSubCategory, hideMobilePricingCategoriesSelector,
  context: { isMobile },
}) => (
  <Container>
    {isMobile && (
      <MobileDivClose onClick={hideMobilePricingCategoriesSelector}>
        {getSubCategory(selectedSubCategory).label}
        <SmallText>Fermer</SmallText>
      </MobileDivClose>
    )}
    {categories.map(({
      slug: categorySlug, label: categoryLabel, image, subCategories,
    }, index) => (
      <CategoriesContainer key={categorySlug}>
        <CategoryContainer
          onClick={() => selectCategory(categorySlug)}
          isSelected={selectedCategory === categorySlug}
          isMain
          hasBorderBottom={index < categories.length - 1 || selectedCategory === categorySlug}
          isFirst={index === 0}
        >
          <FirstPartCategory>
            <Icon src={image} alt={categoryLabel} />
            <CategoryLabelContainer>
              {categorySlug === 'creation' && <YellowDiv>Nouveau !</YellowDiv>}
              <div>{categoryLabel}</div>
            </CategoryLabelContainer>
          </FirstPartCategory>
          <img src={selectedCategory === categorySlug ? arrowBottom : arrowRight} alt={categoryLabel} />
        </CategoryContainer>
        {selectedCategory === categorySlug
          && subCategories.map(({ slug: subCategorySlug, label: subCategoryLabel }, indexSubCategory) => (
            <CategoryContainer
              key={subCategorySlug}
              onClick={() => selectSubCategory(subCategorySlug)}
              isSelected={selectedSubCategory === subCategorySlug}
              hasBorderBottom={index < categories.length - 1 || indexSubCategory < subCategories.length - 1}
            >
              {subCategoryLabel}
            </CategoryContainer>
          ))}
      </CategoriesContainer>
    ))}
  </Container>
);

PricingCategoriesSelector.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  selectedSubCategory: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectSubCategory: PropTypes.func.isRequired,
  hideMobilePricingCategoriesSelector: PropTypes.func,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

PricingCategoriesSelector.defaultProps = {
  hideMobilePricingCategoriesSelector() {},
};

export default withAppContext(PricingCategoriesSelector);
