import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withAppContext from '../../../withAppContext';
import PricesList from './PricesList';
import PricingCategoriesSelector from './PricingCategoriesSelector';
import {
  colors, mobileThresholdPixels, margins, fontSizes, Title1 as StyledTitle1, Button as StyledButton,
} from './styledComponents';
import { getSubCategory } from './pricesTree';
import arrowBottom from '../../../assets/pricing/arrow.bottom.svg';
import CTALink from '../../CTALink';

const Button = styled(StyledButton)`
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 20px;
    width: calc(100vw - 48px);
    margin-top: 0px;
  }
`;

const Title1 = styled(StyledTitle1)`
  max-width: 898px;
  margin: auto;
  margin-top: 91px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
    font-size: 26px;
    margin-top: 40px;
  }
`;

const ButtonContainer = styled.div`
  margin: ${margins.l} 0px 0px 0px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px 0px 0px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  margin: 88px 0px 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    border-width: 0px;
    align-items: center;
    width: 100%;
    margin: 35px 0px 17px;
  }
`;

const MobileSelectedSubCategoryContainer = styled.div`
  background: ${colors.white};
  border: 0.75px solid ${colors.blueGrey};
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 24px 24px;
  width: calc(100vw - 48px);
`;

const CTAButton = ({ label }) => (
  <ButtonContainer>
    <Button navy>
      {label}
    </Button>
  </ButtonContainer>
);

CTAButton.propTypes = {
  label: PropTypes.string,
};

CTAButton.defaultProps = {
  label: 'Prendre rendez-vous',
};

const Pricings = ({
  openAllPricings, isGaleriesLafayette, selectedCategory,
  selectedSubCategory, selectCategory, selectSubCategory,
  showMobilePricingCategoriesSelector, context: { isMobile },
  isWebView,
}) => (
  <div>
    <Title1 fontSize={fontSizes.x2l} lowercase>
      {!isGaleriesLafayette
        ? 'Les mêmes prix que la retoucherie d’en bas'
        : 'Service de retouche à domicile en partenariat avec les Galeries Lafayette'}
    </Title1>
    <SubContainer>
      {isMobile && (
        <MobileSelectedSubCategoryContainer onClick={showMobilePricingCategoriesSelector}>
          {getSubCategory(selectedSubCategory).label}
          <img src={arrowBottom} alt={getSubCategory(selectedSubCategory).label} />
        </MobileSelectedSubCategoryContainer>
      )}
      {!isMobile && (
        <PricingCategoriesSelector
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          selectCategory={selectCategory}
          selectSubCategory={selectSubCategory}
        />
      )}
      <PricesList subCategory={selectedSubCategory} openAllPricings={openAllPricings} />
    </SubContainer>
    {!isWebView && (
      <CTALink fromInteraction="Pricings">
        <CTAButton />
      </CTALink>
    )}
  </div>
);

Pricings.propTypes = {
  openAllPricings: PropTypes.func,
  isGaleriesLafayette: PropTypes.bool,
  selectedCategory: PropTypes.string.isRequired,
  selectedSubCategory: PropTypes.string.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectSubCategory: PropTypes.func.isRequired,
  showMobilePricingCategoriesSelector: PropTypes.func,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  isWebView: PropTypes.bool,
};

Pricings.defaultProps = {
  openAllPricings() {},
  isGaleriesLafayette: false,
  showMobilePricingCategoriesSelector() {},
  isWebView: false,
};

export default withAppContext(Pricings);
