import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { margins, mobileThresholdPixels }
  from '../v2/StyledComponents';
import iosImg from '../../../assets/footer/appStore.svg';
import androidImg from '../../../assets/footer/googlePlay.svg';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 20px;
  }
`;

const Icon = styled.img`
  width: 120px;
  margin: 8px 17px ${margins.m} 0px;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100px;
    margin: 0px 7px 30px;
  }
`;

const A = styled.a.attrs({
  target: '_blank',
})`
  width: fit-content;
  text-decoration: none;
  color: inherit;
`;

const MobileApps = ({ smallTopMargin }) => (
  <Container>
    <A
      target="_blank"
      rel="noreferrer noopener"
      href="https://itunes.apple.com/us/app/tilli/id1280578121"
    >
      <Icon smallTopMargin={smallTopMargin} src={iosImg} alt="Télécharger l’appli mobile pour iPhone" />
    </A>
    <A
      target="_blank"
      rel="noreferrer noopener"
      href="https://play.google.com/store/apps/details?id=fr.tilli.app"
    >
      <Icon smallTopMargin={smallTopMargin} src={androidImg} alt="Télécharger l’appli mobile pour Android" />
    </A>
  </Container>
);

MobileApps.propTypes = {
  smallTopMargin: PropTypes.bool,
};

MobileApps.defaultProps = {
  smallTopMargin: false,
};

export default MobileApps;
