import customerFeedbacks from './customerFeedbacks';

export const embroideriesLabels = [
  'Initiales',
  'Mot entier écrit en petite police',
  'Dessin creux de moins de 5cm',
  'Dessin plein simple de moins de 5cm',
];

export const items = {
  pant: {
    label: 'Pantalon',
    category: 'cloth',
    type: 'SIMPLE',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet simple', id: '9.pant-rac-1' },
        { label: 'Bas de jambe à cintrer', id: '9.pant-cin-3' },
        { label: 'Ceinture à cintrer', id: '9.pant-cin-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '9.pant-div-16' },
        { label: 'Couture simple décousue à reprendre', id: '9.pant-div-21' },
        { label: 'Déchirure à réparer', id: '9.pant-div-20' },
      ],
      embroideries: ['9.pant-bro-1', '9.pant-bro-2', '9.pant-bro-5', '9.pant-bro-8'],
    },
  },
  pant_complex: {
    label: 'Pantalon de tailleur',
    category: 'cloth',
    type: 'COMPLEX',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet simple', id: '9.pant-rac-1' },
        { label: 'Bas de jambe à cintrer', id: '9.pant-cin-3' },
        { label: 'Ceinture à cintrer', id: '9.pant-cin-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '9.pant-div-16' },
        { label: 'Couture simple décousue à reprendre', id: '9.pant-div-21' },
        { label: 'Déchirure à réparer', id: '9.pant-div-20' },
      ],
      embroideries: ['9.pant-bro-1', '9.pant-bro-2', '9.pant-bro-5', '9.pant-bro-8'],
    },
    customerFeedback: customerFeedbacks.pant,
  },
  robe: {
    label: 'Robe',
    category: 'cloth',
    type: 'SIMPLE',
    isFeminine: true,
    works: {
      ordinaryAlterations: [
        { label: 'Bustier ou taille à cintrer', id: '9.robe-cin-5' },
        { label: 'Ourlet simple', id: '9.robe-rac-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '9.robe-div-17' },
        { label: 'Déchirure à réparer', id: '9.robe-div-21' },
        { label: 'Couture simple décousue à reprendre', id: '9.robe-div-22' },
      ],
      embroideries: ['9.robe-bro-1', '9.robe-bro-2', '9.robe-bro-5', '9.robe-bro-8'],
    },
  },
  jupe: {
    label: 'Jupe',
    category: 'cloth',
    type: 'SIMPLE',
    isFeminine: true,
    works: {
      ordinaryAlterations: [
        { label: 'Ceinture à cintrer', id: '9.jupe-cin-1' },
        { label: 'Ourlet simple', id: '9.jupe-rac-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '9.jupe-div-16' },
        { label: 'Déchirure à réparer', id: '9.jupe-div-20' },
        { label: 'Couture simple décousue à reprendre', id: '9.jupe-div-21' },
      ],
      embroideries: ['9.jupe-bro-1', '9.jupe-bro-2', '9.jupe-bro-5', '9.jupe-bro-8'],
    },
  },
  mari: {
    label: 'Robe de mariée',
    category: 'cloth',
    type: 'DEFAULT',
    isFeminine: true,
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet simple', id: '9.mari-rac-1' },
        { label: 'Ourlet à volants', id: '9.mari-rac-2' },
        { label: 'Bustier ou taille à cintrer', id: '9.mari-cin-5' },
        { label: 'Dos à cintrer ou élargir', id: '9.mari-cin-6' },
        { label: 'Épaules à cintrer', id: '9.mari-cin-2' },
        { label: 'Doublure à ajouter, remplacer ou retirer', id: '9.mari-div-9' },
        { label: 'Fente à ajouter', id: '9.mari-div-6' },
        { label: 'Pince à ajouter', id: '9.mari-div-5' },
      ],
      embroideries: ['9.mari-bro-1', '9.mari-bro-2', '9.mari-bro-5', '9.mari-bro-8'],
    },
    customerFeedback: customerFeedbacks.mari,
  },
  robe_complex: {
    label: 'Robe de soirée',
    category: 'cloth',
    type: 'COMPLEX',
    isFeminine: true,
    works: {
      ordinaryAlterations: [
        { label: 'Bustier ou taille à cintrer', id: '9.robe-cin-5' },
        { label: 'Ourlet simple', id: '9.robe-rac-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '9.robe-div-17' },
        { label: 'Déchirure à réparer', id: '9.robe-div-21' },
        { label: 'Couture simple décousue à reprendre', id: '9.robe-div-22' },
      ],
      embroideries: ['9.robe-bro-1', '9.robe-bro-2', '9.robe-bro-5', '9.robe-bro-8'],
    },
    customerFeedback: customerFeedbacks.robe,
  },
  mant: {
    label: 'Veste et manteau',
    category: 'cloth',
    type: 'DEFAULT',
    isFeminine: true,
    secondPossessive: 'son',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '9.mant-cin-4' },
        { label: 'Longueur à raccourcir', id: '9.mant-rac-1' },
        { label: 'Bouton à ajouter ou remplacer', id: '9.mant-div-15' },
        { label: 'Déchirure à réparer', id: '9.mant-div-22' },
        { label: 'Couture simple décousue à reprendre', id: '9.mant-div-25' },
      ],
      embroideries: ['9.mant-bro-1', '9.mant-bro-2', '9.mant-bro-5', '9.mant-bro-8'],
    },
    customerFeedback: customerFeedbacks.mant,
  },
  haut: {
    label: 'Haut',
    category: 'cloth',
    type: 'SIMPLE',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '9.haut-cin-4' },
        { label: 'Longueur à raccourcir', id: '9.haut-rac-1' },
        { label: 'Bouton à ajouter ou remplacer', id: '9.haut-div-15' },
        { label: 'Déchirure à réparer', id: '9.haut-div-22' },
        { label: 'Couture simple décousue à reprendre', id: '9.haut-div-23' },
      ],
      embroideries: ['9.haut-bro-1', '9.haut-bro-2', '9.haut-bro-5', '9.haut-bro-8'],
    },
  },
  pull: {
    label: 'Pull et gilet',
    category: 'cloth',
    type: 'SIMPLE',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '9.pull-cin-5' },
        { label: 'Coudières à ajouter ou remplacer', id: '9.pull-div-2' },
        { label: 'Bouton à ajouter ou remplacer', id: '9.pull-div-14' },
        { label: 'Déchirure à réparer', id: '9.pull-div-21' },
        { label: 'Couture simple décousue à reprendre', id: '9.pull-div-24' },
      ],
      embroideries: ['9.pull-bro-1', '9.pull-bro-2', '9.pull-bro-5', '9.pull-bro-8'],
    },
  },
  pull_complex: {
    label: 'Pull en laine ou cachemire',
    category: 'cloth',
    type: 'COMPLEX',
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '9.pull-cin-5' },
        { label: 'Coudières à ajouter ou remplacer', id: '9.pull-div-2' },
        { label: 'Bouton à ajouter ou remplacer', id: '9.pull-div-14' },
        { label: 'Déchirure à réparer', id: '9.pull-div-21' },
        { label: 'Couture simple décousue à reprendre', id: '9.pull-div-24' },
      ],
      embroideries: ['9.pull-bro-1', '9.pull-bro-2', '9.pull-bro-5', '9.pull-bro-8'],
    },
  },
  haut_complex: {
    label: 'Chemise',
    category: 'cloth',
    type: 'COMPLEX',
    isFeminine: true,
    works: {
      ordinaryAlterations: [
        { label: 'Dos à cintrer', id: '9.haut-cin-4' },
        { label: 'Longueur à raccourcir', id: '9.haut-rac-1' },
        { label: 'Bouton à ajouter ou remplacer', id: '9.haut-div-15' },
        { label: 'Déchirure à réparer', id: '9.haut-div-22' },
        { label: 'Couture simple décousue à reprendre', id: '9.haut-div-23' },
      ],
      embroideries: ['9.haut-bro-1', '9.haut-bro-2', '9.haut-bro-5', '9.haut-bro-8'],
    },
    customerFeedback: customerFeedbacks.haut,
  },
  ling: {
    label: 'Lingerie et maillot de bain',
    category: 'cloth',
    type: 'SIMPLE',
    isFeminine: true,
    secondPossessive: 'son',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet', id: '9.ling-rac-3' },
        { label: 'Bretelles à ajouter ou remplacer', id: '9.ling-div-1' },
        { label: 'Déchirure à réparer', id: '9.ling-div-22' },
        { label: 'Élastique à ajouter ou remplacer', id: '9.ling-div-7' },
        { label: 'Couture simple décousue à reprendre', id: '9.ling-div-23' },
      ],
      embroideries: ['9.ling-bro-1', '9.ling-bro-2', '9.ling-bro-5', '9.ling-bro-8'],
    },
    customerFeedback: customerFeedbacks.ling,
  },
  acce: {
    label: 'Accessoire',
    category: 'cloth',
    type: 'SIMPLE',
    works: {
      ordinaryAlterations: [
        { label: 'Bouton à ajouter ou remplacer', id: '9.acce-div-13' },
        { label: 'Déchirure à réparer', id: '9.acce-div-20' },
        { label: 'Couture simple décousue à reprendre', id: '9.acce-div-21' },
      ],
      embroideries: ['9.acce-bro-1', '9.acce-bro-2', '9.acce-bro-5', '9.acce-bro-8'],
    },
  },
  ride: {
    label: 'Rideau',
    category: 'deco',
    type: 'DEFAULT',
    works: {
      ordinaryAlterations: [
        { label: 'Ourlet pour un rideau de moins de 2m de large', id: '9.deco-rid-1' },
        { label: 'Couture simple décousue à reprendre', id: '9.deco-rid-28' },
        { label: 'Déchirure à réparer', id: '9.deco-rid-27' },
      ],
      embroideries: ['9.deco-bro-1', '9.deco-bro-2', '9.deco-bro-5', '9.deco-bro-8'],
    },
    customerFeedback: customerFeedbacks.ride,
  },
  cous: {
    label: 'Housse',
    category: 'deco',
    type: 'DEFAULT',
    isFeminine: true,
    works: {
      ordinaryAlterations: [
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '9.deco-cou-18' },
        { label: 'Déchirure à réparer', id: '9.deco-cou-22' },
        { label: 'Couture simple décousue à reprendre', id: '9.deco-cou-23' },
      ],
      embroideries: ['9.deco-bro-1', '9.deco-bro-2', '9.deco-bro-5', '9.deco-bro-8'],
    },
  },
  mais: {
    label: 'Linge de maison',
    category: 'deco',
    type: 'DEFAULT',
    works: {
      ordinaryAlterations: [
        { label: 'Côté de moins d’1m à raccourcir', id: '9.deco-mai-7' },
        { label: 'Côté de moins d’1m60 à raccourcir', id: '9.deco-mai-4' },
        { label: 'Côté de plus d’1m60 à raccourcir', id: '9.deco-mai-1' },
        { label: 'Fermeture éclair à ajouter, remplacer ou retirer', id: '9.deco-mai-24' },
        { label: 'Déchirure à réparer', id: '9.deco-mai-28' },
        { label: 'Couture simple décousue à reprendre', id: '9.deco-mai-29' },
      ],
      embroideries: ['9.deco-bro-1', '9.deco-bro-2', '9.deco-bro-5', '9.deco-bro-8'],
    },
  },
  bag: {
    label: 'Sac en tissu',
    category: 'creation',
    type: 'SIMPLE_SMALL',
    sizes: [
      { label: 'Sac de petite taille (20 x 30cm)', id: '9.crea_sac-dime-1' },
      { label: 'Sac de taille moyenne (30 x 30cm)', type: 'SIMPLE_MEDIUM', id: '9.crea_sac-dime-2' },
      { label: 'Sac de grande taille (plus de 30 x 30cm)', type: 'SIMPLE_BIG', id: '9.crea_sac-dime-3' },
    ],
    handles: [
      { label: 'Anse en chaîne', id: '9.crea_sac-anse-1' },
      { label: 'Anse en tissu', id: '9.crea_sac-anse-2' },
    ],
    closures: [
      { label: 'Bouton classique', id: '9.crea_sac-ferm-1' },
      { label: 'Bouton pression', id: '9.crea_sac-ferm-2' },
      { label: 'Fermeture éclair', id: '9.crea_sac-ferm-4' },
    ],
  },
  tote: {
    label: 'Tote bag',
    category: 'creation',
    type: 'SIMPLE_SMALL',
    sizes: [
      { label: 'Tote bag de taille classique (35 x 40cm)', id: '9.crea_tote-dime-1' },
      { label: 'Tote bag de grande taille (40 x 50cm)', type: 'SIMPLE_BIG', id: '9.crea_tote-dime-2' },
    ],
    handles: [
      { label: 'Anse en chaîne', id: '9.crea_tote-anse-1' },
      { label: 'Anse en tissu', id: '9.crea_tote-anse-2' },
    ],
    closures: [
      { label: 'Bouton classique', id: '9.crea_tote-ferm-1' },
      { label: 'Bouton pression', id: '9.crea_tote-ferm-2' },
      { label: 'Fermeture éclair', id: '9.crea_tote-ferm-4' },
    ],
  },
  clut: {
    label: 'Pochette',
    category: 'creation',
    type: 'SIMPLE_SMALL',
    noHandleOption: true,
    sizes: [
      { label: 'Pochette de petite taille (15 x 10cm)', id: '9.crea_poch-dime-1' },
      { label: 'Pochette de taille moyenne (20 x 20cm)', type: 'SIMPLE_MEDIUM', id: '9.crea_poch-dime-2' },
      { label: 'Pochette de grande taille (plus de 25 x 25cm)', type: 'SIMPLE_BIG', id: '9.crea_poch-dime-3' },
    ],
    handles: [
      { label: 'Anse en chaîne', id: '9.crea_poch-anse-1' },
      { label: 'Anse en tissu', id: '9.crea_poch-anse-2' },
    ],
    closures: [
      { label: 'Bouton classique', id: '9.crea_poch-ferm-1' },
      { label: 'Bouton pression', id: '9.crea_poch-ferm-2' },
      { label: 'Fermeture éclair', id: '9.crea_poch-ferm-4' },
    ],
  },
  cous2: {
    label: 'Housse',
    category: 'creation',
    type: 'SIMPLE_SMALL',
    sizes: [
      { label: 'Housse de moins de 45 cm', id: '9.crea_hous-dime-1' },
      { label: 'Housse de plus de 45 cm', type: 'SIMPLE_BIG', id: '9.crea_hous-dime-2' },
    ],
    closures: [
      { label: 'Fermeture éclair', id: '9.crea_hous-ferm-4' },
    ],
  },
};

export const getSubCategories = (category) => (
  Object.keys(items)
    .filter((item) => items[item].category === category)
    .map((item) => ({ slug: item, label: items[item].label }))
);

export const getSubCategory = (subCategory) => items[subCategory];
