import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, mobileThresholdPixels } from '../../../tilli-components/src/components/styledComponents';

import pant from '../../../assets/clothes/jeans.png';
import haut from '../../../assets/clothes/shirt.png';
import robe from '../../../assets/clothes/dress.png';
import pull from '../../../assets/clothes/pullover.png';
import mant from '../../../assets/clothes/jacket.png';
import jupe from '../../../assets/clothes/skirt.png';
import ling from '../../../assets/clothes/panties.png';
import deco from '../../../assets/clothes/window.png';
import mari from '../../../assets/clothes/wedding.png';

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const InputItem = styled.div`
  width: ${(props) => (props.half ? '50%' : '33%')};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 18px;

  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding-left: 22px;
  }
`;

const Radio = styled.div`
  margin-right: 19px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${(props) => (props.isSelected ? colors.navy : colors.white)};
  border: 1px solid ${colors.navy};
  box-sizing: border-box;
`;

const clothesImages = {
  pant, haut, robe, pull, mant, jupe, ling, deco, mari,
};

const ClothImg = styled.img`
  height: 40px;
  margin-right: 14px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-right: 0px;
  }
`;

const ClothItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: -30px;
    width: 80%;
    max-width: 80%;
    flex-direction: column;
    text-align: center;
  }
`;

const StyledInputItem = styled(InputItem)`
  margin-bottom: 33px;

  @media (max-width: ${mobileThresholdPixels}) {
    align-items: flex-start;
    width: calc(50% - 30px);
    padding-left: 30px;
  }
`;

const ClothName = styled.span`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 5px;
  }
`;

const StyledRadio = styled(Radio)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 14px;
  }
`;

const ClothesPricingSelector = ({ pricings, setCloth, selectedClothSlug }) => (
  <InputContainer>
    {pricings
      .filter((cloth) => !(cloth.types?.length > 2))
      .map((cloth) => (
        <StyledInputItem
          key={cloth.slug}
          onClick={() => setCloth(cloth.slug)}
        >
          <StyledRadio isSelected={selectedClothSlug === cloth.slug} />
          <ClothItem>
            {cloth.slug && clothesImages[cloth.slug]
              && <ClothImg src={clothesImages[cloth.slug]} alt={cloth.name} />}
            <ClothName>{cloth.name}</ClothName>
          </ClothItem>
        </StyledInputItem>
      ))}
  </InputContainer>
);

ClothesPricingSelector.propTypes = {
  pricings: PropTypes.arrayOf(PropTypes.shape({})),
  setCloth: PropTypes.func,
  selectedClothSlug: PropTypes.string,
};

ClothesPricingSelector.defaultProps = {
  pricings: [],
  setCloth() {},
  selectedClothSlug: null,
};

export default ClothesPricingSelector;
