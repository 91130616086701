import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import AnimateHeight from 'react-animate-height';

import routesMap from '../../../Routes';
import Dots from './Dots';
import {
  colors, fontSizes, Title1 as StyledTitle1, mobileThresholdPixels,
} from './styledComponents';

const Span1 = styled.span`
  font-size: ${fontSizes.m};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;
const Span2 = styled.span`
  font-size: ${fontSizes.s};
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xs};
  }
`;

const A = styled.a`
  color: inherit;`;
const Title1 = styled(StyledTitle1)`
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 18px;
    margin: 0px 24px;
  }
`;

const Container = styled.div`
  background-color: ${colors.lightGrey5};
  padding-top: 80px;
  padding-bottom: 64px;
  margin-top: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.navy};
  @media (max-width: ${mobileThresholdPixels}) {
    padding-top: 47px;
    padding-bottom: 40px;
    margin-top: 41px;
  }
`;

const SubContainer = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  width: 1062px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    padding: 0px 24px;
    box-sizing: border-box;
    margin-top: -15px;
  }
`;

const QuestionContainer = styled.div`
  border: 0.75px solid ${colors.blueGrey};
  box-sizing: border-box;
  border-radius: 3px;
  padding: 24px 35px;
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  cursor-pointer;
  margin-bottom: 16px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    align-items: flex-start;
    font-size: 16px;
  }
`;

const Title = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 83%;
  }
`;

const TitleIcon = styled.div`
  font-size: 24px;
  cursor: pointer;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 20px;
    margin-left: 34px;
  }
`;

const AnswerContainer = styled.div`
  max-width: 891px;
  margin-top: 20px;
  line-height: 28px;
`;

const paragraphs = [
  {
    id: 1,
    title: 'Y a-t-il des frais de déplacement et de livraison ?',
    text: <Span1>
      Les frais de déplacement des couturiers sont de 5€. Ils peuvent exceptionnellement être de 15€ pour les villes
      d’Ile-de-France situées dans la grande couronne ou celles qui ne sont pas desservies par le métro.
    </Span1>,
  },
  {
    id: 2,
    title: 'Le vêtement de mon client a une doublure, va-t-il payer plus ?',
    text: <Span1>
      Pour toute retouche, si son vêtement contient une doublure à l’intérieur,
      cela entraîne un travail supplémentaire pour le couturier, donc un ajustement des prix.
    </Span1>,
    smallText: <Span2>
      {'Pour en savoir plus, faites son '}
      <Link
        style={{ color: 'inherit' }}
        to={routesMap.Step1.url}
      >
        devis détaillé
      </Link>
    </Span2>,
  },
  {
    id: 3,
    title: 'Les prix sont-ils les mêmes le soir et le week-end ?',
    text: <Span1>
      Oui, seuls le déplacement du couturier et le travail effectué
      sont pris en compte dans l’estimation des prix et aucune majoration n’est appliquée le soir et le week-end 😊.
    </Span1>,
  },
  {
    id: 4,
    title: 'Y a-t-il un minimum de commande ?',
    text: <Span1>
      Oui, un rendez-vous engage un minimum de commande de 25€ afin de sécuriser le déplacement
      du couturier et de dédommager son temps de conseil passé avec votre client.
    </Span1>,
  },
  {
    id: 5,
    title: 'Mon client peut-il payer en espèces ?',
    text: <Span1>
      Pour des raisons légales, nous n’acceptons pas les règlements en espèces.
      Avant de pouvoir utiliser pleinement les services proposés par Tilli,
      il devra avoir renseigné ses coordonnées bancaires (visa ou mastercard).
      Dès lors, il n’aura aucune démarche particulière à effectuer.
      Le paiement sécurisé est assuré par notre partenaire
      {' '}
      <A href="https://www.mangopay.com">Mangopay</A>
      .
          </Span1>,
  },
  {
    id: 6,
    title: 'Quand mon client sera-t-il débité ?',
    text: <Span1>
      Il sera débité après son premier rendez-vous avec l’un de nos Tillistes et la validation du devis avec lui.
    </Span1>,
  },
  {
    id: 7,
    title: 'Mon client n’est pas sûr du type de retouche à faire. Est-ce qu’il peut quand même prendre rendez-vous ?',
    text: <Span1>
      Oui, biensûr ! Mais nous lui conseillons quand même de préciser un maximum de détails
      lors de sa prise de rendez-vous pour que nous soyons capable de lui envoyer
      le couturier le plus qualifié pour ses demandes.
      Chaque matière est spécifique, et nous avons des couturiers aux compétences multiples et très différentes 😉.
    </Span1>,
  },
  {
    id: 8,
    title: 'Y a-t-il des frais d’annulation ?',
    text: <Span1>
      Votre client peut décaler son rendez-vous avec son couturier sous réserve que
      l’annulation soit faite au minimum 8h avant l’heure initiale du rendez-vous.
      Entre 8h et 2h avant, la somme de 14€ lui sera facturée, et moins de 2h avant ou pendant le rendez-vous,
      la somme de 25€ lui sera facturée.
      Pour annuler son rendez-vous, il lui suffit de nous contacter par mail à contact@tilli.fr.
    </Span1>,
  },
];

class PricingsFAQ extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedQuestions: [1],
    };
    this.selectQuestion = this.selectQuestion.bind(this);
  }

  selectQuestion(id) {
    const { selectedQuestions } = this.state;
    let newSelectedQuestions;
    if (selectedQuestions.includes(id)) {
      newSelectedQuestions = selectedQuestions.filter((selectedQuestion) => id !== selectedQuestion);
    } else {
      newSelectedQuestions = [...selectedQuestions, id];
    }
    this.setState({ selectedQuestions: newSelectedQuestions });
  }

  render() {
    const { selectedQuestions } = this.state;
    return (
      <Container>
        <Title1 lowercase fontSize="32px">Vos questions sur nos tarifs</Title1>
        <Dots />
        <SubContainer>
          {paragraphs.map((paragraph) => (
            <QuestionContainer
              key={paragraph.id}
              onClick={() => this.selectQuestion(paragraph.id)}
              showAnswer={selectedQuestions.includes(paragraph.id)}
            >
              <TitleContainer>
                <Title>{paragraph.title}</Title>
                <TitleIcon>{selectedQuestions.includes(paragraph.id) ? '-' : '+'}</TitleIcon>
              </TitleContainer>
              <AnimateHeight
                duration={500}
                height={!selectedQuestions.includes(paragraph.id) ? 0 : 'auto'}
              >
                <AnswerContainer>
                  {paragraph.text}
                  {paragraph.smallText && (
                    <span>
                      <br />
                      <br />
                      {paragraph.smallText}
                    </span>
                  )}
                </AnswerContainer>
              </AnimateHeight>
            </QuestionContainer>
          ))}
        </SubContainer>
      </Container>
    );
  }
}

export default PricingsFAQ;
